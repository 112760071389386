<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Sicherheit_Rollen_1_V1"
        text="Rollen legen die Berechtigungen für Benutzer innerhalb des Systems fest. Einige Rollen sind voreingestellt und können nicht gelöscht werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-supervisor-circle"
                title="Rollen"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="roles"
                  :search="search"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  item-key="meta.id"
                >
                  <!-- <template v-slot:header>
                    <v-toolbar outlined flat class="mb-4">
                      <v-text-field
                        v-model="search"
                        clearable
                        flat
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Suche"
                        style="max-width:380px"
                      ></v-text-field>
                      <template>
                        <v-spacer></v-spacer>
                        <v-select
                          v-model="sortBy"
                          flat
                          outlined
                          dense
                          hide-details
                          :items="keys"
                          prepend-inner-icon="mdi-filter"
                          label="Sortieren nach"
                        ></v-select>
                        <v-spacer></v-spacer>
                        <v-btn-toggle dense v-model="sortDesc" mandatory>
                          <v-btn outlined :value="true">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn outlined :value="false">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </template>
                    </v-toolbar>
                  </template> -->

                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <v-card link @click="routeDetails(item.meta.id)">
                          <v-card-title class="subheading">
                            {{ item.title }}
                            <v-spacer></v-spacer>
                            <v-tooltip bottom v-if="item.isLocked">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-if="item.isLocked"
                                  dense
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-lock</v-icon
                                >
                              </template>
                              <span>Rolle kann nicht bearbeitet werden.</span>
                            </v-tooltip>
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.description }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Rollen importieren"
      text="Importierte Rollen stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              group-by="module"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Rollen suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th :colspan="helpers.dataImport.tableHeaders.length++">
                  <v-icon @click="toggle" class="mr-2"
                    >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                  {{ stripPrefix(items[0].module) }}
                </th>
              </template>

              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ stripPrefix(item.title) }}</span
                  >
                  <small class="grey--text">{{ item.description }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_ROLES, CREATE_ROLES } from "@/store/action-types.js";
import { ADMINISTRATION_SECURITY_ROLES_create } from "@/data/permission-types.js";
import { importableRoles } from "@/data/import/importable-roles.js";

export default {
  name: "admin-security-tab-roles",
  components: {
    TippCard,
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableRoles,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
          snackbar: { model: false, text: "" },
        },
      },

      search: "",
      filter: {},
      sortDesc: true,
      sortBy: "title",
      keys: ["Bezeichnung", "Beschreibung", "ID"],
      filteredKeys: ["ID"],

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          permission: `${ADMINISTRATION_SECURITY_ROLES_create}`,
          actionStyle: "textBtn",
          function: this.rolleErstellen,
        },
        {
          title: "Importieren",
          icon: "mdi-import",
          permission: `${ADMINISTRATION_SECURITY_ROLES_create}`,
          actionStyle: "textBtn",
          function: this.toggleImportDialog,
        },
      ],

      menuItems: [
        {
          disabled: false,
          title: "Zuordnen",
          icon: "mdi-account-arrow-left",
          function: this.test,
        },
        {
          disabled: false,
          title: "Bearbeiten",
          icon: "mdi-pencil",
          function: this.test,
        },
        {
          disabled: false,
          title: "Löschen",
          icon: "mdi-delete",
          function: this.test,
        },
      ],
      lockedRoles: [
        {
          roleKey: "guest",
          bezeichnung: "Gast",
          beschreibung:
            "Nicht bestätigte Nutzer oder Gäste haben keinen Zugriff auf die Organisation. Diese Rolle eignet sich auch für eine temporäre Deaktivierung jeglichen Zugriffs.",
        },
        {
          roleKey: "user",
          bezeichnung: "Teilnehmer",
          beschreibung:
            "Teilnehmer haben Zugriff auf grundlegende Funktionen und können persönliche Inhalte bearbeiten.",
        },
        {
          roleKey: "support",
          bezeichnung: "Support",
          beschreibung:
            "Der Support erhält nach Freigabe durch eine berechtigte Person, Administratorberechtigungen für einen begrenztend Zeitraum. Aktionen werden im Lgobuch dokumentiert.",
        },
        {
          roleKey: "owner",
          bezeichnung: "Organisationsbesitzer",
          beschreibung:
            "Organisationsbesitzer besitzen alle Rechte des Organisationsadministrators.",
        },
        {
          roleKey: "admin",
          bezeichnung: "Organisationsadministrator",
          beschreibung:
            "Organisationsadministratoren besitzen vollen Zugriff auf die Organisation und verwalten alle Einstellungen etc.",
        },
      ],
    };
  },
  computed: {
    roles() {
      return this.$store.state.admin.roles;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_ROLES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    rolleErstellen() {
      this.$router.push({ name: "admin-security-tab-roles-role-new" });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("–") + 1);
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        const preparedRoles =
          this.helpers.dataImport.selectedImportTemplates.map((obj) => {
            const newObj = { ...obj }; // Create a copy of the object
            delete newObj.module; // Delete the "module" property from the copied object
            return newObj; // Return the object without the "module" property
          });

        this.$store
          .dispatch(`${ADMIN}${CREATE_ROLES}`, {
            organizationId: this.$route.params.organizationId,

            roles: preparedRoles,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.dataImport.snackbar.model = true;
            this.helpers.dataImport.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Veranstaltungsart aus, um den Import duchrzuführen."
        );
      }
    },
    routeDetails(item) {
      this.$router.push({
        name: "admin-security-tab-roles-role-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
  },
};
</script>
