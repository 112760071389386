<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Sicherheit_Allgemein_1_V1"
        text="Konfiguriere die Sicherheitseinstellungen deiner Organisation."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-cogs"
                title="Gruppe für neue Benutzer"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <div class="subtitle-1">
                        Diese Benutzergruppe wird auf neue Nutzer angewendet,
                        die zu dieser Organisation eingeladen werden, soweit
                        nicht abweichende Einstellungen festgelegt werden.
                      </div>
                      <v-form
                        v-model="formIsValid"
                        @submit.prevent="updateStandard"
                      >
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              v-model="securityConfig.defaultUserGroupId"
                              :items="userGroups"
                              item-text="title"
                              item-value="meta.id"
                              required
                              :rules="[rules.required]"
                              outlined
                              label="Benutzergruppe auswählen"
                              class="mt-4"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="success"
                          :disabled="!formIsValid"
                          @click="updateStandard()"
                        >
                          <v-icon left> mdi-content-save </v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ADMIN } from "@/store/modules.js";
import {
  GET_USER_GROUPS,
  UPDATE_STANDARD_USER_GROUP,
} from "@/store/action-types.js";

export default {
  name: "admin-security-tab-general",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Erforderlich.",
      },
      formIsValid: false,
    };
  },
  computed: {
    userGroups() {
      return this.$store.state.admin.userGroups;
    },
    securityConfig() {
      return this.$store.state.organization.activeOrganization.config.security;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    updateStandard() {
      if (this.formIsValid) {
        this.$store.dispatch(`${ADMIN}${UPDATE_STANDARD_USER_GROUP}`, {
          organizationId: this.$route.params.organizationId,
          security: {
            defaultUserGroupId: this.securityConfig.defaultUserGroupId,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>
