import {
  ACCOUNTING,
  ACCOUNTING_TIME_SHEETS_create,
  ACCOUNTING_TIME_SHEETS_read,
  ACCOUNTING_TIME_SHEETS_update,
  ACCOUNTING_TIME_SHEETS_delete,
  ACCOUNTING_TIME_SHEETS_read_all,
  ACCOUNTING_TIME_SHEETS_manage_all,
  ACCOUNTING_PAYROLL_create,
  ACCOUNTING_PAYROLL_read,
  ACCOUNTING_PAYROLL_update,
  ACCOUNTING_PAYROLL_delete,
  //   ACCOUNTING_FORECASTS_read,
  ACCOUNTING_ARCHIVE_read,
  ACCOUNTING_CONFIG_read,
  ACCOUNTING_CONFIG_GENERAL_read,
  ACCOUNTING_CONFIG_GENERAL_update,
  ACCOUNTING_CONFIG_PAYROLL_TYPES_create,
  ACCOUNTING_CONFIG_PAYROLL_TYPES_read,
  ACCOUNTING_CONFIG_PAYROLL_TYPES_update,
  ACCOUNTING_CONFIG_PAYROLL_TYPES_delete,
} from "@/data/permission-types.js";

export const accounting = {
  id: `${ACCOUNTING}`,
  name: "Stundenabrechnung",
  disabled: false,
  info: "",
  icon: "mdi-account-clock",
  children: [
    {
      id: "accounting.timeSheets",
      name: "Stundenmeldungen",
      disabled: false,
      chipsName: "Stundenmeldungen",
      info: "",
      icon: "",
      children: [
        {
          id: `${ACCOUNTING_TIME_SHEETS_create}`,
          name: "Stundenmeldung erstellen",
          disabled: false,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${ACCOUNTING_TIME_SHEETS_read}`,
          name: "Stundenmeldung lesen",
          disabled: false,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${ACCOUNTING_TIME_SHEETS_update}`,
          name: "Stundenmeldung bearbeiten",
          disabled: false,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${ACCOUNTING_TIME_SHEETS_delete}`,
          name: "Stundenmeldung löschen",
          disabled: true,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        {
          id: `${ACCOUNTING_TIME_SHEETS_read_all}`,
          name: "Alle Stundenmeldungen lesen",
          disabled: false,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Alle lesen)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${ACCOUNTING_TIME_SHEETS_manage_all}`,
          name: "Alle Stundenmeldungen verwalten",
          disabled: false,
          chipsName: "Stundenabrechnung > Stundenmeldungen (Alle verwalten)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },

    {
      id: "accounting.payroll",
      name: "Abrechnungszettel",
      disabled: false,
      chipsName: "Abrechnungszettel",
      info: "",
      icon: "",
      children: [
        {
          id: `${ACCOUNTING_PAYROLL_create}`,
          name: "Abrechnungszettel erstellen",
          disabled: true,
          chipsName: "Stundenabrechnung > Abrechnungszettel (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_read}`,
          name: "Abrechnungszettel lesen",
          disabled: false,
          chipsName: "Stundenabrechnung > Abrechnungszettel (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_update}`,
          name: "Abrechnungsstapel bearbeiten",
          disabled: true,
          chipsName: "Stundenabrechnung > Abrechnungszettel (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_delete}`,
          name: "Abrechnungszettel löschen",
          disabled: true,
          chipsName: "Stundenabrechnung > Abrechnungszettel (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: "accounting.payroll",
      name: "Abrechnungsstapel",
      disabled: false,
      chipsName: "Abrechnungsstapel",
      info: "",
      icon: "",
      children: [
        {
          id: `${ACCOUNTING_PAYROLL_create}`,
          name: "Abrechnungsstapel erstellen",
          disabled: false,
          chipsName: "Stundenabrechnung > Abrechnungsstapel (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_read}`,
          name: "Abrechnungsstapel lesen",
          disabled: false,
          chipsName: "Stundenabrechnung > Abrechnungsstapel (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_update}`,
          name: "Abrechnungsstapel bearbeiten",
          disabled: false,
          chipsName: "Stundenabrechnung > Abrechnungsstapel (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${ACCOUNTING_PAYROLL_delete}`,
          name: "Abrechnungsstapel löschen",
          disabled: false,
          chipsName: "Stundenabrechnung > Abrechnungsstapel (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: `${ACCOUNTING_ARCHIVE_read}`,
      name: "Archiv",
      disabled: false,
      chipsName: "Stundenabrechnung > Archiv",
      info: "",
      dependencies: [`${ACCOUNTING_TIME_SHEETS_read_all}`],
      icon: "",
    },
    {
      id: "accounting.konfiguration",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${ACCOUNTING_CONFIG_read}`, //TODO:
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Stundenabrechnung > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: `${ACCOUNTING_CONFIG_GENERAL_read}`,
          name: "Konfiguration lesen",
          disabled: false,
          chipsName: "Stundenabrechnung > Konfiguration > Allgemein (Lesen)",
          info: "",
          dependencies: [`${ACCOUNTING_CONFIG_read}`],
          icon: "mdi-eye",
        },
        {
          id: `${ACCOUNTING_CONFIG_GENERAL_update}`,
          name: "Konfiguration bearbeiten",
          disabled: false,
          chipsName: "Stundenabrechnung > Konfiguration > Allgemein",
          info: "",
          dependencies: [
            `${ACCOUNTING_CONFIG_GENERAL_read}`,
            `${ACCOUNTING_CONFIG_read}`,
          ],
          icon: "mdi-pencil",
        },
        {
          id: "Stundenabrechnung.konfiguration.Abrechnungsarten",
          name: "Abrechnungsarten",
          disabled: false,
          info: "",
          children: [
            {
              id: `${ACCOUNTING_CONFIG_PAYROLL_TYPES_create}`,
              name: "Abrechnungsarten erstellen",
              disabled: false,
              chipsName:
                "Stundenabrechnung > Konfiguration > Abrechnungsarten (Erstellen)",
              info: "",
              icon: "mdi-plus-circle",
            },
            {
              id: `${ACCOUNTING_CONFIG_PAYROLL_TYPES_read}`,
              name: "Abrechnungsarten lesen",
              disabled: false,
              chipsName:
                "Stundenabrechnung > Konfiguration > Abrechnungsarten (Lesen)",
              info: "",
              icon: "mdi-eye-circle",
            },
            {
              id: `${ACCOUNTING_CONFIG_PAYROLL_TYPES_update}`,
              name: "Abrechnungsarten bearbeiten",
              disabled: false,
              chipsName:
                "Stundenabrechnung > Konfiguration > Abrechnungsarten (Bearbeiten)",
              info: "",
              icon: "mdi-pencil-circle",
            },
            {
              id: `${ACCOUNTING_CONFIG_PAYROLL_TYPES_delete}`,
              name: "Abrechnungsarten löschen",
              disabled: false,
              chipsName:
                "Stundenabrechnung > Konfiguration > Abrechnungsarten (Löschen)",
              info: "",
              icon: "mdi-delete-circle",
            },
          ],
        },
      ],
    },
  ],
};
