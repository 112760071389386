import {
  ADMINISTRATION,
  ADMINISTRATION_CONFIG_read,
  ADMINISTRATION_CONFIG_GENERAL_read,
  ADMINISTRATION_CONFIG_APPEARANCE_read,
  ADMINISTRATION_CONFIG_INHERITANCE_read,
  // ADMINISTRATION_CONFIG_UNITS_read,
  // ADMINISTRATION_CONFIG_POSITIONS_read,
  ADMINISTRATION_BILLING_read,
  ADMINISTRATION_USERS_read,
  ADMINISTRATION_USERS_ACCOUNTS_read,
  ADMINISTRATION_USERS_GROUPS_read,
  ADMINISTRATION_SECURITY_read,
  ADMINISTRATION_SECURITY_GENERAL_read,
  ADMINISTRATION_SECURITY_ROLES_read,
  ADMINISTRATION_PRIVACY_read,
  ADMINISTRATION_SECURITY_API_read,
  ADMINISTRATION_EXTENSIONS_read,
  ADMINISTRATION_AUDIT_read,
} from "@/data/permission-types.js";

export const admin = {
  id: `${ADMINISTRATION}`,
  name: "Administration",
  disabled: false,
  info: "",
  icon: "mdi-cog",
  children: [
    {
      id: "administration.konfiguration",
      name: "Konfiguration",
      disabled: false,
      info: "",
      children: [
        {
          id: `${ADMINISTRATION_CONFIG_read}`,
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Administration > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: "administration.konfiguration.more",
          name: "Berechtigungen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${ADMINISTRATION_CONFIG_GENERAL_read}`,
              name: "Allgemein",
              disabled: false,
              chipsName: "Administration > Konfiguration > Allgemein",
              dependencies: [`${ADMINISTRATION_CONFIG_read}`],
              info: "",
              icon: "",
            },
            {
              id: `${ADMINISTRATION_CONFIG_APPEARANCE_read}`,
              name: "Erscheinungsbild",
              disabled: false,
              chipsName: "Administration > Konfiguration > Erscheinungsbild",
              dependencies: [`${ADMINISTRATION_CONFIG_read}`],
              info: "",
              icon: "",
            },
            {
              id: `${ADMINISTRATION_CONFIG_INHERITANCE_read}`,
              name: "Organisationstruktur",
              disabled: false,
              chipsName:
                "Administration > Konfiguration > Organisationsstruktur",
              dependencies: [`${ADMINISTRATION_CONFIG_read}`],
              info: "",
              icon: "",
            },
          ],
        },
      ],
    },
    {
      id: `${ADMINISTRATION_BILLING_read}`,
      name: "Abrechnung",
      disabled: false,
      chipsName: "Administration > Abrechnung (Ansicht)",
      info: "",
      icon: "",
    },
    {
      id: "administration.benutzer",
      name: "Benutzer",
      disabled: false,
      info: "",
      icon: "",
      children: [
        {
          id: `${ADMINISTRATION_USERS_read}`,
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Administration > Benutzer (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: "administration.benutzer.more",
          name: "Berechtigungen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${ADMINISTRATION_USERS_ACCOUNTS_read}`,
              name: "Benutzerkonten",
              disabled: false,
              chipsName: "Administration > Benutzer > Benutzerkonten (Ansicht)",
              info: "",
              icon: "",
              dependencies: [`${ADMINISTRATION_USERS_read}`],
            },
            {
              id: `${ADMINISTRATION_USERS_GROUPS_read}`,
              name: "Benutzergruppen",
              disabled: false,
              chipsName:
                "Administration > Benutzer > Benutzergruppen (Ansicht)",
              info: "",
              icon: "",
              dependencies: [`${ADMINISTRATION_USERS_read}`],
            },
          ],
        },
      ],
    },
    {
      id: "administration.sicherheit",
      name: "Sicherheit",
      disabled: false,
      chipsName: "Administration > Sicherheit (Ansicht)",
      info: "",
      icon: "",
      children: [
        {
          id: `${ADMINISTRATION_SECURITY_read}`,
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Administration > Sicherheit (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: "administration.sicherheit.more",
          name: "Berechtigungen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${ADMINISTRATION_SECURITY_GENERAL_read}`,
              name: "Allgemein",
              disabled: false,
              chipsName: "Administration > Sicherheit > Allgemein",
              info: "",
              dependencies: [`${ADMINISTRATION_SECURITY_read}`],
              icon: "",
            },
            {
              id: `${ADMINISTRATION_SECURITY_ROLES_read}`,
              name: "Rollen",
              disabled: false,
              chipsName: "Administration > Sicherheit > Rollen",
              info: "",
              dependencies: [`${ADMINISTRATION_SECURITY_read}`],
              icon: "",
            },
            // {
            //   id: "administration.konfiguration.zuordnung",
            //   name: "Zuordnung",
            //   disabled: false,
            //   chipsName: "Administration > Sicherheit > Zuordnung",
            //   info: "",
            //   icon: "",
            // },
            {
              id: `${ADMINISTRATION_SECURITY_API_read}`,
              name: "API",
              disabled: false,
              chipsName: "Administration > Sicherheit > API",
              info: "",
              dependencies: [`${ADMINISTRATION_SECURITY_read}`],
              icon: "",
            },
          ],
        },
      ],
    },
    {
      id: "admin.datenschutz",
      name: "Datenschutz",
      disabled: false,
      chipsName: "Administration > Datenschutz",
      info: "",
      icon: "",
      children: [
        {
          id: `${ADMINISTRATION_PRIVACY_read}`,
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Administration > Sicherheit (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: "administration.datenschutz.more",
          name: "Berechtigungen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${ADMINISTRATION_SECURITY_GENERAL_read}`,
              name: "Allgemein",
              disabled: false,
              chipsName: "Administration > Datenschutz > Allgemein",
              info: "",
              dependencies: [`${ADMINISTRATION_PRIVACY_read}`],
              icon: "",
            },
            {
              id: `${ADMINISTRATION_SECURITY_ROLES_read}`,
              name: "Rollen",
              disabled: false,
              chipsName:
                "Administration > Datenschutz > Datenschutzerklärung (Organisation)",
              info: "",
              dependencies: [`${ADMINISTRATION_PRIVACY_read}`],
              icon: "",
            },
          ],
        },
      ],
    },
    {
      id: `${ADMINISTRATION_EXTENSIONS_read}`,
      name: "Erweiterungen",
      disabled: false,
      chipsName: "Administration > Erweiterungen (Ansicht)",
      info: "",
      icon: "",
    },
    {
      id: `${ADMINISTRATION_AUDIT_read}`,
      name: "Audit",
      disabled: false,
      chipsName: "Administration > Audit (Ansicht)",
      info: "",
      icon: "",
    },
  ],
};
