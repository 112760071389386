import {
  MY_ORGANIZATION,
  MY_ORGANIZATION_ASSIGNMENTS_read,
  MY_ORGANIZATION_NEWS_create,
  MY_ORGANIZATION_NEWS_read,
  MY_ORGANIZATION_NEWS_update,
  MY_ORGANIZATION_NEWS_delete,
  MY_ORGANIZATION_UNITS_read,
  MY_ORGANIZATION_ORGANIZATIONAL_CHART_read,
  MY_ORGANIZATION_SURVEYS_create,
  MY_ORGANIZATION_SURVEYS_read,
  MY_ORGANIZATION_SURVEYS_update,
  MY_ORGANIZATION_SURVEYS_delete,
} from "@/data/permission-types.js";

export const myOrganization = {
  id: `${MY_ORGANIZATION}`,
  name: "Meine Organisation",
  disabled: false,
  info: "",
  icon: "mdi-home",
  children: [
    {
      id: "meineOrganisation.news",
      name: "Neuigkeiten",
      disabled: false,
      chipsName: "Neuigkeiten",
      info: "",
      icon: "",
      children: [
        {
          id: `${MY_ORGANIZATION_NEWS_create}`,
          name: "Neuigkeiten erstellen",
          disabled: false,
          chipsName: "Meine Organisation > Neuigkeiten (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${MY_ORGANIZATION_NEWS_read}`,
          name: "Neuigkeiten lesen",
          disabled: false,
          chipsName: "Meine Organisation > Neuigkeiten (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${MY_ORGANIZATION_NEWS_update}`,
          name: "Neuigkeiten bearbeiten",
          disabled: false,
          chipsName: "Meine Organisation > Neuigkeiten (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${MY_ORGANIZATION_NEWS_delete}`,
          name: "Neuigkeiten löschen",
          disabled: false,
          chipsName: "Meine Organisation > Neuigkeiten (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: "meineOrganisation.umfragen",
      name: "Umfragen",
      disabled: false,
      chipsName: "Umfragen",
      info: "",
      icon: "",
      children: [
        {
          id: `${MY_ORGANIZATION_SURVEYS_create}`,
          name: "Umfragen erstellen",
          disabled: false,
          chipsName: "Meine Organisation > Umfragen (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${MY_ORGANIZATION_SURVEYS_read}`,
          name: "Umfragen lesen",
          disabled: false,
          chipsName: "Meine Organisation > Umfragen (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${MY_ORGANIZATION_SURVEYS_update}`,
          name: "Umfragen bearbeiten",
          disabled: false,
          chipsName: "Meine Organisation > Umfragen (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${MY_ORGANIZATION_SURVEYS_delete}`,
          name: "Umfragen löschen",
          disabled: false,
          chipsName: "Meine Organisation > Umfragen (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: `${MY_ORGANIZATION_UNITS_read}`,
      name: "Einsatzmittel",
      disabled: false,
      chipsName: "Meine Organisation > Einsatzmittel",
      info: "",
      icon: "",
    },
    {
      id: `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
      name: "Organigramm",
      disabled: false,
      chipsName: "Meine Organisation > Organigramm",
      info: "",
      icon: "",
    },
    {
      id: `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
      name: "Aufgabengebiete",
      disabled: false,
      chipsName: "Meine Organisation > Aufgabenverteilung",
      info: "",
      icon: "",
    },
  ],
};
