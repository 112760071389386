<template>
  <div>
    <section>
      <TippCard
        hintID="Admin_Sicherheit_Zuordnung_1_V1"
        text="Einem Benutzer können mehrere Rollen zugeordnet werden. Überschneiden sich Berechtigungen erhält der Benutzer den Zugriff, wenn zumindest eine Rolle diesen gewährt."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-security"
                title="Zuordnungsmatrix"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-data-table
                    :headers="computedHeaders"
                    :items="membershipsPrivate"
                    fixed-header
                    height="600px"
                    group-by="groupBy"
                    dense
                    :items-per-page="50"
                    :footer-props="footerProps"
                  >
                    <template
                      v-slot:[`group.header`]="{ items, isOpen, toggle }"
                    >
                      <th :colspan="computedHeaders.length">
                        <v-icon @click="toggle" class="mr-2"
                          >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>

                        {{ stripPrefix(items[0].groupBy) }}
                      </th>
                    </template>
                    <template v-slot:[`item.user.displayName`]="{ item }">
                      <router-link
                        :to="{
                          name: 'admin-users-tab-accounts-account-details',
                          params: {
                            organizationId: $route.params.organizationId,
                            itemId: item.user.uid,
                          },
                        }"
                        class="text-decoration-none primary--text"
                        >{{ item.user.displayName }}</router-link
                      >
                    </template>
                    <template v-slot:[`item.checkbox`]="{ item, header }">
                      <!-- :disabled="item[header.isLocked]" -->
                      <v-simple-checkbox
                        disabled
                        v-model="item[header.id]"
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <router-view />
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_MEMBERSHIPS_PRIVATE, GET_ROLES } from "@/store/action-types.js";
export default {
  name: "admin-security-tab-matrix",
  components: {
    TippCard,
    Subheader,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [25, 50, 75, 100, -1],
      },
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    membershipsPrivate() {
      var memberships = this.$store.state.admin.membershipsPrivate;
      var membershipsWithRoles = memberships.map((user) => {
        const data = {
          ...user,
          ...this.getRolesData(user.access.roles.allRolesIds),
          groupBy:
            user.group.sortKey.toString().padStart(4, "0") +
            "_" +
            user.group.title,
        };
        return data;
      });
      return membershipsWithRoles;
    },
    roles() {
      return this.$store.state.admin.roles;
    },
    computedHeaders() {
      const headers = [
        {
          text: "Name",
          sortable: true,
          value: "user.displayName",
          divider: true,
        },
      ];
      const roles = this.roles.map((role) => {
        const container = {
          text: role.title,
          sortable: false,
          value: "checkbox",
          id: role.meta.id,
          isLocked: role.isLocked,
          align: "center",
        };
        return container;
      });
      return headers.concat(roles);
    },
    computedData() {
      return [];
    },
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_MEMBERSHIPS_PRIVATE}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      this.$store.dispatch(`${ADMIN}${GET_ROLES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getRolesData(rolesOfUser) {
      var result;
      var data;
      this.roles.forEach((role) => {
        const newObject = {
          [role.meta.id]: rolesOfUser.includes(role.meta.id),
        };
        return (data = { ...data, ...newObject });
      });
      return { ...result, ...data };
    },
  },
};
</script>
