<template>
  <div>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-alert
            type="warning"
            border="left"
            colored-border
            icon="mdi-alert"
            elevation="2"
            class="mt-6"
          >
            <div class="title">Hinweis</div>
            <div class="mt-2">Diese Konfiguration ist deaktiviert.</div>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "admin-security-tab-api",
  components: {},
};
</script>
