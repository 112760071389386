<template>
  <div>
    <NavigationBar
      titel="Neue Rolle anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
        class="col s8"
      >
        <role-editor v-model="role" mode="new"></role-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { ADMIN } from "@/store/modules.js";
import { CREATE_ROLE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import RoleEditor from "@/components/admin/security/roles/RoleEditor.vue";

export default {
  name: "admin-security-tab-roles-role-new",
  components: {
    NavigationBar,
    RoleEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      helpers: {
        formIsValid: false,
      },
      search: null,
      open: ["all_access"],

      role: {
        title: null,
        description: null,
        permissions: [],
      },
    };
  },
  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${ADMIN}${CREATE_ROLE}`, {
          organizationId: this.$route.params.organizationId,
          title: this.role.title,
          description: this.role.description,
          permissions: this.role.permissions,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
