<template>
  <div>
    <NavigationBar
      titel="Rolle bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
        class="col s8"
      >
        <role-editor v-model="role" mode="edit"></role-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { UPDATE_ROLE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import RoleEditor from "@/components/admin/security/roles/RoleEditor.vue";

export default {
  name: "admin-security-tab-roles-role-edit",
  components: {
    NavigationBar,
    RoleEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      helpers: {
        formIsValid: false,
      },

      role: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("roles")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.role = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${ADMIN}${UPDATE_ROLE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.role.title,
          description: this.role.description,
          permissions: this.role.permissions,
          meta: {
            isLocked: this.role.meta.isLocked,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
