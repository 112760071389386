<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader
              icon="mdi-account-supervisor-circle"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-col md="12" cols="12">
                      <v-text-field
                        v-model.trim="roleLocal.title"
                        :counter="35"
                        label="Bezeichnung"
                        :rules="[rules.required]"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model.trim="roleLocal.description"
                        counter
                        clearable
                        no-resize
                        label="Beschreibung"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <v-alert
              type="warning"
              border="left"
              colored-border
              icon="mdi-alert"
              elevation="2"
              class="mt-6"
            >
              <div class="title">Hinweis</div>
              <div class="mt-2">
                Die Steuerung von Berechtigungen für deaktivierte Funktionen ist
                nicht möglich. Sobald die Funktionen einzeln freigeschaltet
                werden, können die Berechtigungen verwaltet werden. Du wirst
                darüber gesondert informiert, um die Berechtigungen deiner
                Rollen entsprechend anzupassen.
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-account-supervisor-circle"
              title="Berechtigungen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-col cols="12" sm="12" md="12">
                      <v-card-actions class="mb-1">
                        <v-text-field
                          v-model="search"
                          label="Suchen"
                          outlined
                          hide-details
                          clearable
                          class="mr-2"
                        ></v-text-field>
                        <v-btn
                          text
                          @click="roleLocal.permissions = []"
                          :disabled="roleLocal.permissions.length === 0"
                        >
                          Zurücksetzen
                        </v-btn>
                      </v-card-actions>
                      <v-row>
                        <v-col>
                          <v-card-text>
                            <v-treeview
                              v-model="roleLocal.permissions"
                              dense
                              :items="permissions"
                              :search="search"
                              :filter="filter"
                              selected-color="primary"
                              selection-type="leaf"
                              item-disabled="disabled"
                              open-on-click
                              selectable
                              :open.sync="openNodes"
                              transition
                              expand-icon="mdi-chevron-down"
                            >
                              <template v-slot:label="{ item }">
                                <v-icon
                                  v-if="item.icon"
                                  dense
                                  :disabled="item.disabled"
                                  class="mr-1"
                                >
                                  {{ item.icon }}
                                </v-icon>
                                {{ item.name }}
                              </template>
                              <template v-slot:append="{ item }">
                                <v-menu
                                  offset-y
                                  bottom
                                  left
                                  :nudge-width="100"
                                  :max-width="420"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="item.dependencies"
                                      small
                                      depressed
                                      v-bind="attrs"
                                      v-on="on"
                                      :color="getBtnColor(item.dependencies)"
                                    >
                                      <v-icon small left
                                        >mdi-shield-alert</v-icon
                                      >Abhängigkeit
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title
                                      class="font-weight-medium subtitle-1 text-wrap"
                                    >
                                      Berechtigung setzt weitere Berechtigungen
                                      voraus:
                                    </v-card-title>
                                    <v-card-text>
                                      <li
                                        v-for="permissionId in item.dependencies"
                                        :key="permissionId"
                                        :class="
                                          roleLocal.permissions.includes(
                                            permissionId
                                          )
                                            ? 'green--text'
                                            : 'red--text'
                                        "
                                      >
                                        {{
                                          getPermissionTitleFromId(permissionId)
                                        }}
                                      </li>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-btn
                                        depressed
                                        @click="closeMenu"
                                        class="ml-2"
                                        >Abbrechen</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        depressed
                                        color="primary"
                                        class="mr-2"
                                        @click="
                                          addDependencies(item.dependencies)
                                        "
                                        >Berechtigungen erteilen</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </template>
                            </v-treeview>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { permissionsTree } from "@/data/permission-tree.js";
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "role-editor",
  props: {
    role: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      openNodes: ["all_access"],
      search: null,
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "role",
    event: "roleChange",
  },
  computed: {
    roleLocal: {
      get: function () {
        return this.role;
      },
      set: function (value) {
        this.$emit("roleChange", value);
      },
    },
    permissions() {
      return permissionsTree;
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    getPermissionTitleFromId(permissionId) {
      // Hilfsfunktion zur rekursiven Suche
      const findPermission = (permissions, id) => {
        for (const permission of permissions) {
          if (permission.id === id) {
            return permission;
          }
          if (permission.children) {
            const found = findPermission(permission.children, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };

      // Suche nach der Berechtigung
      const permission = findPermission(this.permissions, permissionId);
      return permission ? permission.name : "";
    },
    addDependencies(dependencies) {
      // Füge die Abhängigkeiten hinzu ohne duplikate
      dependencies.forEach((dependency) => {
        if (!this.roleLocal.permissions.includes(dependency)) {
          this.roleLocal.permissions.push(dependency);
        }
      });
      this.closeMenu();
    },
    getBtnColor(dependencies) {
      // Überprüfe, ob alle Elemente von dependencies in this.roleLocal.permissions enthalten sind
      const allIncluded = dependencies.every((dep) =>
        this.roleLocal.permissions.includes(dep)
      );

      // Gib 'success' zurück, wenn alle Abhängigkeiten enthalten sind, andernfalls 'error'
      return allIncluded ? "success" : "error";
    },
    closeMenu() {
      this.$refs.menu.closeMenu();
    },
  },
};
</script>
