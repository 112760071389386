import {
  MEMBER_MANAGEMENT_ASSIGNMENTS_create,
  MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_update,
  MEMBER_MANAGEMENT_ASSIGNMENTS_delete,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_update,
  MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_delete,
  MEMBER_MANAGEMENT_CONFIG_read,
  MEMBER_MANAGEMENT_CONFIG_SKILLS_create,
  MEMBER_MANAGEMENT_CONFIG_SKILLS_read,
  MEMBER_MANAGEMENT_CONFIG_SKILLS_update,
  MEMBER_MANAGEMENT_CONFIG_SKILLS_delete,
  MEMBER_MANAGEMENT_CONFIG_COURSES_create,
  MEMBER_MANAGEMENT_CONFIG_COURSES_read,
  MEMBER_MANAGEMENT_CONFIG_COURSES_update,
  MEMBER_MANAGEMENT_CONFIG_COURSES_delete,
  MEMBER_MANAGEMENT_CONFIG_RANKS_create,
  MEMBER_MANAGEMENT_CONFIG_RANKS_read,
  MEMBER_MANAGEMENT_CONFIG_RANKS_update,
  MEMBER_MANAGEMENT_CONFIG_RANKS_delete,
  MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_create,
  MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_read,
  MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_update,
  MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_delete,
  MEMBER_MANAGEMENT_CONFIG_HONORS_create,
  MEMBER_MANAGEMENT_CONFIG_HONORS_read,
  MEMBER_MANAGEMENT_CONFIG_HONORS_update,
  MEMBER_MANAGEMENT_CONFIG_HONORS_delete,
  MEMBER_MANAGEMENT_CONFIG_DIVISIONS_create,
  MEMBER_MANAGEMENT_CONFIG_DIVISIONS_read,
  MEMBER_MANAGEMENT_CONFIG_DIVISIONS_update,
  MEMBER_MANAGEMENT_CONFIG_DIVISIONS_delete,
  MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_create,
  MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_read,
  MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_update,
  MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_delete,
} from "@/data/permission-types.js";

export const memberManagement = {
  id: "memberManagement",
  name: "Mitgliederverwaltung",
  disabled: false,
  info: "",
  icon: "mdi-account-group",
  children: [
    {
      id: "memberManagement.assignments",
      name: "Aufgabengebiete",
      disabled: false,
      chipsName: "Aufgabengebiete",
      info: "",
      icon: "",
      children: [
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
          name: "Aufgabengebiete erstellen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
          name: "Aufgabengebiete lesen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
          name: "Aufgabengebiete bearbeiten",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_delete}`,
          name: "Aufgabengebiete löschen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },

    {
      id: "memberManagement.performanceTargets",
      name: "Zielvorgaben",
      disabled: false,
      chipsName: "Zielvorgaben",
      info: "",
      icon: "",
      children: [
        {
          id: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create}`,
          name: "Zielvorgaben erstellen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Zielvorgaben (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read}`,
          name: "Zielvorgaben lesen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Zielvorgaben (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_update}`,
          name: "Zielvorgaben bearbeiten",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Zielvorgaben (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_delete}`,
          name: "Zielvorgaben löschen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Zielvorgaben (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: "memberManagement.config",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Mitgliederverwaltung > Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${MEMBER_MANAGEMENT_CONFIG_read}`,
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        // {
        //   id: `${MEMBER_MANAGEMENT_CONFIG_GENERAL_read}`,
        //   name: "Konfiguration lesen",
        //   disabled: false,
        //   chipsName: "Mitgliederverwaltung > Konfiguration > Allgemein (Lesen)",
        //   info: "",
        //   dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
        //   icon: "mdi-eye",
        // },
        // {
        //   id: `${MEMBER_MANAGEMENT_CONFIG_GENERAL_read}`,
        //   name: "Konfiguration bearbeiten",
        //   disabled: false,
        //   chipsName: "Mitgliederverwaltung > Konfiguration > Allgemein",
        //   info: "",
        //   dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
        //   icon: "mdi-pencil",
        // },
        {
          id: "memberManagement.config.skills",
          name: "Qualifikationen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_create}`,
              name: "Qualifikationen erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Qualifikationen (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_read}`,
              name: "Qualifikationen lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Qualifikationen (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_update}`,
              name: "Qualifikationen bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Qualifikationen (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_SKILLS_delete}`,
              name: "Qualifikationen löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Qualifikationen (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.courses",
          name: "Lehrgänge",
          disabled: false,
          info: "",

          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_COURSES_create}`,
              name: "Lehrgänge erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Lehrgänge (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_COURSES_read}`,
              name: "Lehrgänge lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Lehrgänge (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_COURSES_update}`,
              name: "Lehrgänge bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Lehrgänge (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_COURSES_delete}`,
              name: "Lehrgänge löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Lehrgänge (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.ranks",
          name: "Dienstgrade",
          disabled: false,
          info: "",

          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_RANKS_create}`,
              name: "Dienstgrade erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienstgrade (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_RANKS_read}`,
              name: "Dienstgrade lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienstgrade (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_RANKS_update}`,
              name: "Dienstgrade bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienstgrade (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_RANKS_delete}`,
              name: "Dienstgrade löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienstgrade (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.dutyPositions",
          name: "Dienststellungen",
          disabled: false,
          info: "",

          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_create}`,
              name: "Dienststellungen erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienststellungen (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_read}`,
              name: "Dienststellungen lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienststellungen (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_update}`,
              name: "Dienststellungen bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienststellungen (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DUTY_POSITIONS_delete}`,
              name: "Dienststellungen löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Dienststellungen (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.honors",
          name: "Ehrungen",
          disabled: false,
          info: "",

          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_HONORS_create}`,
              name: "Ehrungen erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Ehrungen (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_HONORS_read}`,
              name: "Ehrungen lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Ehrungen (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_HONORS_update}`,
              name: "Ehrungen bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Ehrungen (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_HONORS_delete}`,
              name: "Ehrungen löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Ehrungen (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.divisions",
          name: "Abteilungen",
          disabled: false,
          info: "",

          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_create}`,
              name: "Abteilungen erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Abteilungen (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_read}`,
              name: "Abteilungen lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Abteilungen (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_update}`,
              name: "Abteilungen bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Abteilungen (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_DIVISIONS_delete}`,
              name: "Abteilungen löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Abteilungen (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "memberManagement.config.customFields",
          name: "Benutzerdefinierte Felder",
          disabled: false,
          info: "",
          children: [
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_create}`,
              name: "Benutzerdefinierte Felder erstellen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Benutzerdefinierte Felder (Erstellen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-plus-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_read}`,
              name: "Benutzerdefinierte Felder lesen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Benutzerdefinierte Felder (Lesen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-eye-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_update}`,
              name: "Benutzerdefinierte Felder bearbeiten",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Benutzerdefinierte Felder (Bearbeiten)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-pencil-circle",
            },
            {
              id: `${MEMBER_MANAGEMENT_CONFIG_CUSTOM_FIELDS_delete}`,
              name: "Benutzerdefinierte Felder löschen",
              disabled: false,
              chipsName:
                "Mitgliederverwaltung > Konfiguration > Benutzerdefinierte Felder (Löschen)",
              info: "",
              dependencies: [`${MEMBER_MANAGEMENT_CONFIG_read}`],
              icon: "mdi-delete-circle",
            },
          ],
        },
      ],
    },
  ],
};
