<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title class="subheading">
                    {{ data.title }}
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="data.isLocked">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="data.isLocked"
                          dense
                          class="mr-1"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-lock</v-icon
                        >
                      </template>
                      <span>Rolle kann nicht bearbeitet werden.</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ data.description }}
                  </v-card-subtitle>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple"
                title="Benutzergruppe mit dieser Rolle"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="benutzergruppeQuery"
                  sort-by="sortKey"
                  items-per-page="5"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          link
                          @click="routeDetailsBenutzergruppe(item.meta.id)"
                        >
                          <v-card-title class="subheading">
                            {{ item.title }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.description }}
                          </v-card-subtitle>
                          <v-card-text
                            ><span>
                              <v-icon small>mdi-sort-numeric-ascending</v-icon>
                              {{ item.sortKey }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-account-box-multiple"
                title="Benutzer mit dieser Rolle"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="benutzerQuery"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  items-per-page="5"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="item in props.items"
                        :key="item.user.uid"
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-card
                          link
                          @click="routeDetailsBenutzer(item.user.uid)"
                        >
                          <v-card-title class="subheading">
                            {{ item.user.displayName }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.group.title }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { DELETE_ROLE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  ADMINISTRATION_SECURITY_ROLES_update,
  ADMINISTRATION_SECURITY_ROLES_delete,
} from "@/data/permission-types.js";

export default {
  name: "admin-security-tab-roles-role-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permissions: `${ADMINISTRATION_SECURITY_ROLES_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permissions: `${ADMINISTRATION_SECURITY_ROLES_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      formIsValid: false,

      sortDesc: true,
      sortBy: "title",
      benutzergruppeQuery: [],
      benutzerQuery: [],

      data: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "data", data: this.data },
        { title: "benutzergruppeQuery", data: this.benutzergruppeQuery },
        { title: "benutzerQuery", data: this.benutzerQuery },
      ];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("roles")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.data = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("userGroups")
        .where("roleIds", "array-contains", this.$route.params.itemId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.benutzergruppeQuery.push(doc.data());
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });

      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("membershipsPrivate")
        .where("access.status", "in", ["activated", "deactivated"])
        .where(
          "access.roles.allRolesIds",
          "array-contains",
          this.$route.params.itemId
        ) // Needs a change, wenn value is array
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              user: {
                displayName: doc.data().user.displayName,
                uid: doc.data().user.uid,
              },
              group: {
                title: doc.data().group.title,
              },
            };
            this.benutzerQuery.push(data);
          });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    createBorderColorStyle(farbe) {
      const style = "border-left: 12px solid " + farbe;
      return style;
    },
    routeDetailsBenutzer(item) {
      this.$router.push({
        name: "admin-users-tab-accounts-account-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    routeDetailsBenutzergruppe(item) {
      this.$router.push({
        name: "admin-users-tab-groups-group-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    editItem() {
      if (this.data.isLocked) {
        alert(
          "Diese Rolle wird vom System geliefert und kann nicht bearbeitet werden."
        );
      } else {
        this.$router.push({
          name: "admin-security-tab-roles-role-edit",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          },
        });
      }
    },
    deleteItem() {
      if (this.data.isLocked) {
        alert(
          "Diese Rolle wird vom System geliefert und kann nicht bearbeitet werden."
        );
      } else if (this.benutzergruppeQuery.length > 0) {
        alert(
          "Fehler: Du kannst nur Rollen löschen, denen keine Benutzergruppen zugeordnet sind."
        );
      } else if (this.benutzerQuery.length > 0) {
        alert(
          "Fehler: Du kannst nur Rollen löschen, denen keine Benutzer zugeordnet sind."
        );
      } else {
        var res = confirm(
          "Bist Du sicher, dass Du diese Rolle löschen möchtest?"
        );
        if (res == true) {
          this.$store.dispatch(`${ADMIN}${DELETE_ROLE}`, {
            organizationId: this.$route.params.organizationId,
            itemId: this.$route.params.itemId,
          });
        }
      }
    },
  },
};
</script>

<style></style>
