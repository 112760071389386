import {
  AVAILABILTY,
  AVAILABILITY_LEAVES_create,
  AVAILABILITY_LEAVES_read,
  AVAILABILITY_LEAVES_update,
  AVAILABILITY_LEAVES_delete,
  AVAILABILITY_LEAVES_read_all,
  AVAILABILITY_LEAVES_manage_all,
  AVAILABILITY_UNITS_create,
  AVAILABILITY_UNITS_read,
  AVAILABILITY_UNITS_update,
  AVAILABILITY_UNITS_delete,
  //   AVAILABILITY_FORECASTS_read,
  AVAILABILITY_ARCHIVE_read,
  AVAILABILITY_CONFIG_read,
  AVAILABILITY_CONFIG_GENERAL_read,
  AVAILABILITY_CONFIG_GENERAL_update,
} from "@/data/permission-types.js";

export const availability = {
  id: `${AVAILABILTY}`,
  name: "Verfügbarkeit",
  disabled: false,
  info: "",
  icon: "mdi-checkbox-multiple-marked-circle",
  children: [
    {
      id: "verfuegbarkeit.Abwesenheiten",
      name: "Abwesenheiten",
      disabled: false,
      chipsName: "Abwesenheiten",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_LEAVES_create}`,
          name: "Abwesenheitsmeldung erstellen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_read}`,
          name: "Abwesenheitsmeldung lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_update}`,
          name: "Abwesenheitsmeldung bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_delete}`,
          name: "Abwesenheitsmeldung löschen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_read_all}`,
          name: "Alle Abwesenheiten lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Alle lesen)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_manage_all}`,
          name: "Alle Abwesenheiten verwalten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Alle verwalten)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },

    {
      id: "verfuegbarkeit.einsatzmittel",
      name: "Einsatzmittel",
      disabled: true,
      chipsName: "Einsatzmittel",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_UNITS_create}`,
          name: "Einsatzmittel erstellen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_read}`,
          name: "Einsatzmittel lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_update}`,
          name: "Einsatzmittel bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_delete}`,
          name: "Einsatzmittel löschen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: "verfuegbarkeit.vorausplanung",
      name: "Vorausplanung",
      disabled: true,
      chipsName: "Vorausplanung",
      info: "",
      icon: "",
      children: [],
    },
    {
      id: `${AVAILABILITY_ARCHIVE_read}`,
      name: "Archiv",
      disabled: false,
      chipsName: "Verfügbarkeit > Archiv",
      info: "",
      dependencies: [`${AVAILABILITY_LEAVES_read_all}`],
      icon: "",
    },
    {
      id: "verfuegbarkeit.konfiguration",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_CONFIG_read}`, //TODO:
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: `${AVAILABILITY_CONFIG_GENERAL_read}`,
          name: "Konfiguration lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration > Allgemein (Lesen)",
          info: "",
          dependencies: [`${AVAILABILITY_CONFIG_read}`],
          icon: "mdi-eye",
        },
        {
          id: `${AVAILABILITY_CONFIG_GENERAL_update}`,
          name: "Konfiguration bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration > Allgemein",
          info: "",
          dependencies: [
            `${AVAILABILITY_CONFIG_GENERAL_read}`,
            `${AVAILABILITY_CONFIG_read}`,
          ],
          icon: "mdi-pencil",
        },
      ],
    },
  ],
};
